<template>
	<v-row justify="center">
		
		<v-col v-if="isPaketEmpty" cols="4">
			<v-row class="flex-column" align-content="center">
				<v-col>
					<v-img src="@/assets/images/data_empty.png" />
				</v-col>
				<v-col>
					<div class="title text-center">Tidak ada program</div>
				</v-col>
				<div class="subtitle body-1 text-center">
					klik tombol di bawah ini untuk menambahkan program baru
				</div>
				<v-col class="text-center">
					<v-btn color="primary" elevation="0">
						<v-icon>ri-add-line</v-icon> Program baru
					</v-btn>
				</v-col>
			</v-row>
		</v-col>

		<v-col v-else>
			<v-row>
			<v-col id="search" lg="6" class="d-flex align-center">          				
				<v-text-field
					outlined
					append-icon="$search"
					class="pt-0 mt-0 mr-4"
					placeholder="Search"
					v-model="name"
					solo
					flat
					@keydown.enter="fetchPeserta"
				>
					<template v-slot:append v-if="name">
						<v-icon @click="name = ''">ri-close-line</v-icon>
					</template>    
				</v-text-field>          
			</v-col>
			<!-- <v-col lg="6" class="d-flex align-end justify-end">
				<v-btn
					class="white--text"
					color="primary"
					:loading="submitting"          
					@click="download"
				>
					<v-icon class="mr-2">ri-download-line </v-icon> Export
				</v-btn>
			</v-col> -->
		</v-row>
			<v-row>
				<v-col id="dt-program">
					<!-- <program-all
						:loading="table_loading"
						@change="change"
						@detail="detail"
						@refresh-list="fetchAll"
						:data="{ perPage: limit, list, page, total }"
					></program-all> -->
					<program-all
							:loading="table_loading"
							:data="data"
							@change="change"
							@detail="detail"
							@sorting="sorting"
							@voucher="voucher"
							@refresh-list="fetchPeserta"
						/>
				</v-col>
			</v-row>
			<v-dialog v-model="is_change.show" max-width="600px" persistent>
				<v-card>
					<v-card-title>
						<span>  Update User </span>
					</v-card-title>
					<v-divider></v-divider>
					<div>
						<v-card-text>
							<v-col cols="12">
								<div class="mb-2">Nama</div>
								<v-text-field required filled hide-details="auto" v-model="member.fullname" class="border-0"></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="mb-2">Nomor Whatsapp</div>
								<v-text-field required filled hide-details="auto" v-model="member.phone" class="border-0" readonly></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="mb-2">Email</div>
								<v-text-field required filled hide-details="auto" v-model="member.email" class="border-0"></v-text-field>
							</v-col>
							<v-col cols="12">
								<div class="mb-2">
									<span class="font-weight-medium">Tanggal Lahir</span>
								</div>
								<v-menu                        
									ref="menu"
									v-model="menu"
									:close-on-content-click="false"
									elevation="0"
								>
									<v-date-picker
										ref="picker"
										v-model="member.date_of_birth"
										min="1950-01-01"
										:max="(new Date()).toISOString()"
										@change="save"
										outlined
										required
									></v-date-picker>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="member.date_of_birth"
											append-icon="ri-calendar-line"
											:rules="dateRules"
											readonly
											outlined
											v-bind="attrs"
											v-on="on"
											class=""
										></v-text-field>
									</template>
								</v-menu>
							</v-col>
							<!-- <v-col cols="12">
								<div class="mb-2">Nomor Kartu Prakerja</div>
								<v-text-field required filled hide-details="auto" v-model="code" class="border-0"></v-text-field>
							</v-col> -->  
						</v-card-text>            
					</div>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn outlined color="primary" small elevation="0" @click="cancel()">
							Batal
						</v-btn>
						<v-btn
							class="white--text"
							color="primary"
							small
							elevation="0"
							:loading="submitting"
							@click="editMember(is_change.id)"
						>
							Ya, Ubah!
						</v-btn>            
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="is_list.show" max-width="600px" scrollable persistent>
				<v-card>
					<v-card-title>
						<span> Detail Aktivitas </span>
					</v-card-title>
					<v-divider></v-divider>
					<v-card-text id="act-topic">
						<v-expansion-panels accordion>
							<v-expansion-panel v-for="(item, index) in topics" :key="index"
							>
								<v-expansion-panel-header class="font-weight-bold">{{ item.name }}</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-list dense class="col-12 pa-0">
										<v-list-item-group>
											<v-divider></v-divider>
											<v-list-item
												v-for="(act, i) in item.activities"
												:key="i"
												class="pl-0"
											>
												<v-list-item-content>
													<div class="d-flex my-1">
														<v-icon
															v-if="act.is_complete === true"
															class="mr-1 mt-0"
															color="green darken-3"
															small
															>ri-checkbox-circle-fill</v-icon
														>
														<div class=" mb-1 font-weight-medium text-capitalize">
															{{ act.title }}
														</div>
													</div>
													<div v-if="act.type == 'evaluasi_praktek_mandiri' && act.is_complete === true">
														<a :href="act.upload.file.url" target="_blank" class="mt-2 pl-3"><v-icon class="mr-2"> ri-survey-line</v-icon> Lihat File</a><br>
														<v-btn
															v-show="btnRespon"
															class="mt-2"
															outlined color="primary" x-small elevation="0"
															@click="respon = true ; btnRespon=false"
														>
															Beri Respon
														</v-btn>
														<v-card v-show="respon">
															<v-card-text>
																<v-col cols="12">
																	<v-text-field filled hide-details="auto" class="border-0" placeholder="Masukkan Nilai" v-model="act.upload.note.score" @keypress="onlyNumber" @keyup="changeScoreNumber"></v-text-field>
																</v-col>
																<v-col cols="12">
																	<v-textarea filled hide-details="auto" class="border-0" placeholder="Masukkan Respon" v-model="act.upload.note.text"></v-textarea>
																</v-col>
															</v-card-text>
															<v-card-actions>
																<v-spacer></v-spacer>
																<v-btn
																	outlined color="primary" x-small elevation="0"
																	@click="respon = false; btnRespon=true"
																>
																	Tutup
																</v-btn>
																<v-btn
																	class="white--text"
																	color="primary"
																	x-small
																	@click="sendFeedback(item.id, act.id, act.upload.note.score, act.upload.note.text)"
																	:loading="submitting"
																>
																	Kirim Respon
																</v-btn>
															</v-card-actions>
														</v-card>
													</div>
													</v-list-item-content>
												</v-list-item>
											</v-list-item-group>
										</v-list>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							class="white--text"
							color="primary"
							small
							elevation="0"
							@click="cancelChange()"
						>
							Tutup
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog v-model="is_voucher.show" max-width="600px" persistent>
				<v-card>
					<v-card-title>
						<span>  Detail Voucher </span>
					</v-card-title>
					<v-divider></v-divider>
					<div>
						<v-card-text>
							<!-- {{ detailVoucher.id }} -->
							<v-simple-table>
								<template v-slot:default>
									<tbody>
										<tr>
											<td>Kode Voucher</td>
											<td>{{ detailVoucher.license }}</td>
										</tr>
										<tr>
											<td>Nama</td>
											<td>{{ detailVoucher.member.fullname }}</td>
										</tr>
										<tr>
											<td>Nomor Whatsapp</td>
											<td>{{ detailVoucher.member.phone }}</td>
										</tr>
										<tr>
											<td>Nama Program</td>
											<td>{{ detailVoucher.program.title }}</td>
										</tr>
										<tr>
											<td>Progress</td>
											<td>{{ detailVoucher.results.progress }} %</td>
										</tr>
										<tr>
											<td>Nilai Pre Test</td>
											<td>{{ detailVoucher.results.scores.pre_test.score }}</td>
										</tr>
										<tr>
											<td>Nilai Post Test</td>
											<td>{{ detailVoucher.results.scores.post_test.final_score }}</td>
										</tr>
										<tr>
											<td>Tanggal Mulai</td>
											<td>{{ currentDateTime(detailVoucher.start_test_at) }}</td>
										</tr>
										<tr>
											<td>Tanggal Penyelesaian</td>
											<td v-if="detailVoucher.end_test_at != null && detailVoucher.results.progress === 100">{{ currentDateTime(detailVoucher.end_test_at) }}</td>
											<td v-else> - </td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>            
					</div>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							class="white--text"
							color="primary"
							small
							elevation="0"
							:loading="submitting"
							@click="is_voucher.show=false"
						>
							Tutup
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-snackbar 
				top 
				v-model="snackbar.state"
				color="primary"
				outlined
				:timeout="timeout"
			>
				<div v-html="snackbar.text"></div>
				<template v-slot:action="{ attrs }">
					<v-btn
						small
						icon
						color="error"
						v-bind="attrs"
						@click="snackbar.state = false"
					>
						<v-icon>$close</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
		</v-col>
	</v-row>
</template>

<script>
import ProgramAll from "./components/table";
import { mapGetters} from 'vuex'
import moment from "moment";


export default {
	name: "user-list",
	components: { ProgramAll},
	data: () => ({
		newPaket: "",
		table_loading: false,
		loading:false,
		all: {},
		member:{},
		topics:[],
		idMember:'',
		tabPaket: null,
		submitting:false,
		is_change: {
			show:false,
			id:''
		},
		snackbar: {
				state: false,
				text: "",
			},
		timeout:5000,
		is_list:{
			show:false,
			id:''
		},
		is_voucher:{
			show:false,
			id:''
		},
		new_status: "",
		new_items:"",
		prog:{}, 
		name:"",
		menu: false,
		date: null,
		age:'',
		respon:false,
		btnRespon:true,
		act: {
			upload: {
				note: {
					text: '',
					score: '',
				},
			}
		},
		detailVoucher:{},
		url_:'',
		code:'',
		title:'',
		dir:'',
		limit:10,
	}),
	computed: {
		isPaketEmpty: () => false,
		// ...mapGetters("user", ["list", "page", "limit", "total"]),
		...mapGetters({ data: "user/data", params: "user/params" }),
		// topics() {
		//   return this.topics || [];
		// },
	},
	mounted() {
		// this.$emit("page-changed", 0, { title: this.title, link:`/program/users/${this.$route.params.id}` });
		this.$emit("page-changed", 1, {
			title: "Daftar Peserta",
			link: this.$route.path,
		});
		this.fetchPeserta();    
		// this.fetchDetail();    
	},
	created(){
	},
	methods: {
		save(date) {
			this.$refs.menu.save(date);
			var today = new Date();
			var birthDate = new Date(this.date);
			this.age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				this.age--;
			}
			return this.age;
		},
		fetchAll(){
			this.fetchPeserta();
		},
		fetchDetail(){
			this.axios
				.get(`/program/v1/company/detail/${this.$route.params.id}`)
				.then((res) =>{
					this.title = res.data.data.title;
				})
		},
		async fetchPeserta() {
			this.table_loading = true;
			// let user = {
			//   id:this.$route.params.id,
			//   q: this.name_program
			// }
			// let q= this.name_program      
			this.$store.dispatch("user/list",{ id:this.$route.params.id, q: this.name, limit:this.limit, sort: 'results.progress', dir: 'desc' }).finally(() => {
				this.table_loading = false;
			});
			
			// this.table_loading = true;
			// this.$set(this.all, "list", []);
			// await this.$store
			//   .dispatch("user/list",this.$route.params.id, {
			//     page: this.all.page || 1,
			//     limit: this.all.limit || 10,
			//     sort: 'created_at', sort_type: 'desc'
			//   })
			//   .then((res) => {
			//     this.all = res.data;
			//   });
			// this.table_loading = false;
		},
		sorting(value) {
			if (value === true) {
				this.dir='desc'
			}else{this.dir='asc'}
			this.table_loading = true;    
			this.$store.dispatch("user/list",{id:this.$route.params.id,q: this.name,sort:'results.progress',dir:this.dir}).finally(() => {
				this.table_loading = false;
			});
		},
		fetchSearch(value) {
			this.table_loading = true;
			this.$store.dispatch("user/list",this.$route.params.id,{
				q: value,page:1}).finally(() => {
				this.table_loading = false;
			});
		},
		detail(program) {
			this.is_list.show=true;
			this.is_list.id=program.id;
			this.getDetail(program.id)
		},
		getDetail(id){
			this.idMember=id;
			this.axios
				.get(`/program/v1/company/detail-member-redeem/${this.idMember}`)
				.then((res) =>{
					this.topics = res.data.data.topics;
				})
		}, 
		getDetailMember(id){
			this.idMember=id;
			this.axios
				.get(`users/v1/member/detail_by_id/${this.idMember}`)
				.then((res) =>{
					this.member = res.data.data;
					this.code = this.member.meta?.code || '';
				})
		}, 
		change(member) {
			this.is_change.show=true;
			this.is_change.id=member;
			this.getDetailMember(member)
		},
		getVoucher(id){
			this.idMember=id;
			this.axios
				.get(`/program/v1/company/detail-member-redeem/${this.idMember}`)
				.then((res) =>{
					this.detailVoucher = res.data.data;
				})
		},
		voucher(member){
			this.is_voucher.show=true;
			this.is_voucher.id=member.id;
			this.getVoucher(member.id)
		},
		currentDateTime(a) {
			return moment(a).format("L");
		},
		editMember(id){
			this.submitting=true;

			const data = {
				fullname: this.member.fullname,
				date_of_birth: this.member.date_of_birth,
				phone: this.member.phone,
				email: this.member.email,
				meta:{code: this.code}
			}
				this.axios
					.put(`users/v1/member/update_by_id/${id}`, data)
					.then(() =>{
						this.submitting=false;
						this.is_change.show=false;
						this.fetchPeserta();
							this.snackbar.text = `Data Peserta berhasil diubah`;
							this.snackbar.state = true;              
					})
					.catch((res) => {
						this.submitting=false;          
						if (res.response) {
							this.snackbar.text = res.response.data.message;
							this.snackbar.state = true;
						}
					});
		},
		// update(item, status){
		//   this.new_items = item;
		//   this.new_status = status;
		// },
		cancel() {
			this.is_change.show = false;
		},
		cancelChange() {
			this.is_list.show = false;
		},
		hapus(id){
			this.is_change.id=id;
			console.log('id delete',this.is_change.id);
			this.submitting=true;
			this.axios
				.delete(`/users/v1/company/program/delete/${this.is_change.id}`)
				.then((res) =>{
					this.submitting=false;
					this.is_change.show=false;
					if (res.status == 200) {
						this.snackbar.text = `Program berhasil dihapus`;
						this.snackbar.state = true;
						this.fetchAll();

					}else{
						this.snackbar.text = res.message;
						this.snackbar.state = true;
					} 
				})
				.catch((error) => {
					console.error(error);
				});
		},
		onlyNumber($event) {
			let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

			if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
				$event.preventDefault();
			}
		},
		changeScoreNumber() {
			if ( parseInt(this.act.upload.note.score) > 100 ) {
				this.act.upload.note.score = 100
			} else if ( parseInt(this.act.upload.note.score) < 0 ) {
				this.act.upload.note.score = 0
			}
		},
		sendFeedback(topic, act, score, text) {
			console.log(this.act.upload.note.text)
			this.submitting = true;
			let params = {
				member_redeem_id: this.idMember,
				topic_id: topic,
				activity_id: act,
				score: parseInt(score),
				text: text,
			}
			this.axios.put(`/users/v1/member/activity/feedback`,params)
			.then((res) => {
				this.btnRespon = true;
				this.submitting = false;
				if (res.status == 200) {
					this.snackbar.text = `Feedback berhasil dikirim`;
					this.snackbar.state = true;
				} else {
					this.snackbar.text = res.message;
					this.snackbar.state = true;
				} 
			})
			.catch((error) => {
				this.submitting = false;
				console.error(error);
			});
		},
		forceFileDownload(url){
			// const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'file.png') //or any other extension
			document.body.appendChild(link)
			link.click()
		},
		download(){
			this.submitting=true;
			// this.url_='https://storage.googleapis.com/yec-cdn/prakerja-new/company/60dd42e4ba82458c8b28896d/documents/1629990319396363000-5f8966a243fb477ab767949c_2021-08-26_15-05-19.xlsx';

			this.axios
				.get(`users/v1/export/member_redeem/${this.$route.params.id}?limit=-1`)
				// .get(`https://cors-anywhere.herokuapp.com/http://c5d3-36-72-213-149.ngrok.io/users/v1/export/member_redeem/${this.$route.params.id}?page=1&limit=1&sort=created_at&dir=desc`)
				.then((res) =>{
					this.submitting=false;          
					this.url_ = res.data.data.public_url;          
						this.forceFileDownload(this.url_)
				})
				.catch((res) => {
					this.submitting=false;
					this.snackbar.text = res.response.data.message;
					this.snackbar.state = true; 
				});
		},
	},
	watch: {
		menu(val) {
			val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
		},
		// name: function(newval){
		//   setTimeout(() => {
		//     this.table_loading = true;              
		//     this.$store.dispatch("user/list",{id:this.$route.params.id,
		//       q: 'Bella',
		//       page:1})
		//       .finally(() => {
		//         this.table_loading = false;
		//       });
		//   },800)
		// },
	},
};
</script>