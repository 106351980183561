export default { 
  Auth: require('./auth').default,
  Dashboard: require('./Dashboard').default,
  Program: require('./program').default,
  Errors: require('./errors').default,
  Programs: require('./programs').default,
  TnA: require('./tnas').default,
  User: require('./user').default,
  Certif: require('./certificate').default,
  Notifikasi: require('./notification').default,
  Reseller: require('./reseller').default,
  Presensi: require('./presensi').default,
  Manage: require('./manage-certif').default
}
