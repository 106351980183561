import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: require('./_auth').default,
    program: require('./_program').default,
    media: require('./_media').default,
    studi: require('./_studi').default,
    user: require('./_user').default,
    notif: require('./_notif').default,
    reseller: require('./_reseller').default,
    presensi: require('./_presensi').default,
    absen: require('./_absen').default,
    activity: require('./_activity').default,
    packet: require('./_packet').default,
    section: require('./_section').default,
  }
})
