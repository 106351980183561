const mixins = {
  data() {
    return {
      // rules: {
      //   required: value => !!value || 'Required.',
      //   counter: value => value.length <= 20 || 'Max 20 characters',
      //   email: value => {
      //     const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //     return pattern.test(value) || 'Invalid e-mail.'
      //   },
      // }
    }
  },
  methods: {
    rules(label, reqRules) {
      const rules = [];
      let rule;

      if (!Array.isArray(reqRules)) {
        reqRules = reqRules.split('|')
      }

      if (reqRules.find(val => val == 'email')) {
        rules.push(
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || label + ' bukan email yang valid.'
          },
        )
      }

      if (reqRules.find(val => val == 'required')) {
        rules.push(
          value => !!value || label + ' tidak boleh kosong'
        )
      }

      rule = reqRules.find(val => val.match('number'))      
      if (rule) {
        // const val = rule.split(':')[1]
        rules.push(
          // value => value >= 0 || label + ' tidak boleh kurang dari 0',
          value => (value >=0 || !!value) || label + ' tidak boleh kosong'
        )
      }      

      rule = reqRules.find(val => val.match('max.'))
      if (rule) {
        const val = rule.split(':')[1]
        rules.push(
          value => (value || '').length <= val || label + ' harus kurang dari atau sama dengan ' + val + ' karakter.'
        )
      }

      rule = reqRules.find(val => val.match('min.'))
      if (rule) {
        const val = rule.split(':')[1]
        rules.push(
          value => (value || '').length >= val || label + ' harus lebih dari atau sama dengan ' + val + ' karakter.'
        )
      }

      rule = reqRules.find(val => val.match('between.'))
      if (rule) {
        const min = rule.split(':')[1].split(',')[0]
        if (min == undefined) {
          throw new Error('min cannot undefined')
        }
        const max = rule.split(':')[1].split(',')[1]
        if (max == undefined) {
          throw new Error('max cannot undefined')
        }
        rules.push(
          value => ((value || '').length >= min && (value || '').length <= max) || label + ' harus berada diantara ' + min + ' dan ' + max + ' karakter'
        )
      }

      return rules;
    }
  }
}

export default mixins