import Axios from "axios";
import url from "./api";

export default {
  namespaced: true,
  state: () => ({
    data: {},
    detail: {},
    params: {},
  }),
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PARAMS(state, data) {
      state.params = data;
    },
    SET_DETAIL(state, data) {
      state.detail = data;
    },
  },
  getters: {
    data: (state) => state.data,
    params: (state) => state.params,
    detail: (state) => state.detail,
  },
  actions: {
    list({ state, commit }, params ) {

      // console.log('params q', params)
      let a ={
        page: state.data.page, limit: state.data.limit
      }

      params = {
        ...a,
        ...state.params,
        ...params,
      };
      // console.log('params', params)
      state.data.list = [];
      return Axios.get(url.user_list.replace("{id}", params.id), { params })
        .then((res) => res.data)
        .then((res) => {
          delete params.page;
          delete params.limit;
          commit("SET_PARAMS", params);
          commit("SET_DATA", res.data);
          return res.data;
        });
    },    
  },
};
